import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Header.css';
import logo from '../assets/logo.svg';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLiveTime, setIsLiveTime] = useState(false);
  const menuRef = useRef(null);
  const menuIconRef = useRef(null);

  const checkLiveTime = () => {
    const now = new Date();
    const day = now.getDay(); // 0 is Sunday
    const hours = now.getHours();
    const minutes = now.getMinutes();
    
    // Only show on Sundays (day === 0)
    // Between 11:00 AM (11:00) and 1:30 PM (13:30)
    return day === 0 && // Sunday
           ((hours === 11 && minutes >= 0) || // After 11:00 AM
            (hours === 12) || // All of 12 PM
            (hours === 13 && minutes <= 30)); // Until 1:30 PM
  };

  useEffect(() => {
    // Initial check
    setIsLiveTime(checkLiveTime());

    // Check every minute
    const interval = setInterval(() => {
      setIsLiveTime(checkLiveTime());
    }, 60000); // 60000 ms = 1 minute

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && 
          menuRef.current && 
          !menuRef.current.contains(event.target) &&
          !menuIconRef.current.contains(event.target)) {
        // Add closing class first
        const nav = menuRef.current;
        nav.classList.add('closing');
        // Wait for animation to finish before closing
        setTimeout(() => {
          setIsMenuOpen(false);
          nav.classList.remove('closing');
        }, 400); // Same duration as our animation
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    if (isMenuOpen) {
      // Add closing class first
      const nav = menuRef.current;
      nav.classList.add('closing');
      // Wait for animation to finish before closing
      setTimeout(() => {
        setIsMenuOpen(false);
        nav.classList.remove('closing');
      }, 400); // Same duration as our animation
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleNavClick = (e) => {
    // Add closing class first
    const nav = menuRef.current;
    nav.classList.add('closing');
    // Wait for animation to finish before closing
    setTimeout(() => {
      setIsMenuOpen(false);
      nav.classList.remove('closing');
    }, 400);
  };

  return (
    <header className="header">
      <div 
        ref={menuIconRef}
        className={`menu-icon ${isMenuOpen ? 'open' : ''}`} 
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="logo-section">
        <a href="/" className="logo-link">
          <div className="logo-container">
            <img src={logo} alt="Church Logo" className="logo" />
            <div className="church-name">
              <div>MOUNT CARMEL</div>
              <div>PENTECOSTAL CHURCH, INC.</div>
            </div>
          </div>
        </a>
        {isLiveTime && (
          <a 
            href="https://www.youtube.com/@MOUNTCARMELPC" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ textDecoration: 'none' }}
          >
            <div className="live-indicator">
              <div className="live-dot"></div>
              <span className="live-text">LIVE</span>
            </div>
          </a>
        )}
      </div>
      <nav 
        ref={menuRef}
        className={`nav-container ${isMenuOpen ? 'open active' : ''}`}
      >
        <ul>
          <li><NavLink to="/" end onClick={handleNavClick}>HOME</NavLink></li>
          <li><NavLink to="/about" onClick={handleNavClick}>ABOUT</NavLink></li>
          {/* <li><NavLink to="/sermons" onClick={handleNavClick}>SERMONS</NavLink></li> */}
          <li><NavLink to="/events" onClick={handleNavClick}>EVENTS</NavLink></li>
          <li><NavLink to="/tithes-offering" onClick={handleNavClick}>GIVE</NavLink></li>
          <li><NavLink to="/shop" onClick={handleNavClick}>SHOP</NavLink></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
