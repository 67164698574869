import React from 'react';
import '../styles/HomePage.css';
import '../styles/HomePageNew.css';
import HomeSermonBanner from '../components/HomeSermonBanner';
import { eventsData } from '../data/eventsData';
import HomePageEventCards from '../components/HomePageEventCards';
import Header from '../components/Header';
import IconSlider from '../components/IconSlider';

const HomePage = () => {
  const socialLinks = [
    { name: 'YouTube', icon: 'icon-youtube', url: '#' },
    { name: 'Instagram', icon: 'icon-instagram', url: '#' },
    { name: 'Facebook', icon: 'icon-facebook', url: '#' },
  ];

  const connectLinks = [
    { name: 'Giving', url: '/tithes-offering', icon: 'icon-giving' },
    { name: 'Calendar', url: '#', icon: 'icon-calendar' },
    { name: 'Prayer Request', url: '/prayer-request', icon: 'icon-prayer-request' },
    { name: 'Contact Us', url: '#', icon: 'icon-contact-us' },
  ];

  const currentDate = new Date();
  console.log('Current date:', currentDate);

  const upcomingEvents = eventsData
    .map(event => {
      const [month, day] = event.date.split(' ');
      const year = currentDate.getFullYear();
      const dateString = `${month} ${day} ${year} ${event.time}`;
      return {
        ...event,
        dateObject: new Date(dateString)
      };
    })
    .filter(event => event.dateObject > currentDate)
    .sort((a, b) => a.dateObject - b.dateObject)
    .slice(0, 3);

  console.log('Upcoming events:', upcomingEvents);

  return (
    <div className="home-page">
      <Header />
      <IconSlider />
      <main className="main-content-new">
        {/* <HomeYoutubeBanner /> */}
        <HomeSermonBanner 
          title="The Upper Room Experience"
          preacher="Pastor Judith Esdaille"
          date="February 11th, 2025"
          backgroundImage="/images/9thpic.jpg"
          alignmentGif="/images/9thgif.gif"
          verses="Mark: 14: 12-26"
        />
        
        <div className="lower-content">
          <div className="left-column">
            <HomePageEventCards />
          </div>
          <div className="sidebar">
            <div className="sidebar-box connect">
              <h3>Connect</h3>
              <ul>
                {connectLinks.map((link, index) => (
                  <li key={index}>
                    <div className={`icon ${link.icon}`}></div>
                    <a href={link.url} className="link">{link.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="sidebar-box follow-on-social">
              <h3>Follow On Social</h3>
              <ul>
                {socialLinks.map((link, index) => (
                  <li key={index}>
                    <div className={`icon ${link.icon}`}></div>
                    <a href={link.url} className="social-link">{link.name}</a>
                    <div className="arrow"></div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;

