import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/EventDetails.css';
import { eventsData } from '../data/eventsData';
import { Link } from 'react-router-dom';

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const EventImages = importAll(require.context('../assets/EventCards', false, /\.(png|jpe?g|svg)$/));

const EventDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const eventData = eventsData.find(e => e.id === parseInt(id));
    if (eventData) {
      setEvent({
        ...eventData,
        image: eventData.title === "Black History Celebration" 
          ? EventImages["blackhistory23rd.png"]
          : EventImages[eventData.image] || ''
      });
    } else {
      navigate('/events');
    }
  }, [id, navigate]);

  if (!event) return <div>Loading...</div>;

  return (
    <div className="event-details-container">
      <div className={`event-image-container ${event.title === "Black History Celebration" ? 'full-height' : ''}`}>
        <img src={event.image} alt={event.title} className="event-image" />
        <div className="event-image-overlay">
          <h1 className="event-title">{event.title}</h1>
        </div>
      </div>
      
      <div className="event-info-grid">
        <div className="left-column">
          <div className="info-box date-time-box">
            <div className="date-section">
              <span className="info-icon">📅</span>
              <div className="info-content">
                <h3>Date</h3>
                <p>{event.date}</p>
              </div>
            </div>
            <div className="time-section">
              <span className="info-icon">🕒</span>
              <div className="info-content">
                <h3>Time</h3>
                <p>{event.time}</p>
              </div>
            </div>
          </div>
          <div className="info-box location-box">
            <span className="info-icon">📍</span>
            <div className="info-content">
              <h3>Location</h3>
              <p>{event.location}</p>
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="info-box about-box">
            <span className="info-icon">ℹ️</span>
            <div className="info-content">
              <h3>About This Event</h3>
              {event.description.split('\n').map((line, index) => {
                // Handle markdown-style bold text (**text**)
                const parts = line.split(/(\*\*.*?\*\*)/g);
                return (
                  <p key={index} className={line.includes('Pricing:') ? 'pricing-header' : ''}>
                    {parts.map((part, partIndex) => {
                      if (part.startsWith('**') && part.endsWith('**')) {
                        // Remove the ** markers and apply bold style
                        return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
                      }
                      return part;
                    })}
                  </p>
                );
              })}
              {event.title === "Vision Board Event" && (
                <div className="donation-section">
                  <Link to="/tithes-offering" className="donate-button">
                    Donate Now
                  </Link>
                  <p className="donation-note">
                    Please label your donation with the tag "vision board" to help us track funds for this event.
                  </p>
                </div>
              )}
              {event.meetingDetails && (
                <>
                  <div className="meeting-details">
                    <p>Meeting ID: {event.meetingDetails.id}</p>
                    <p>Password: {event.meetingDetails.password}</p>
                  </div>
                  <div className="one-tap-mobile">
                    <p>One tap mobile:</p>
                    {event.meetingDetails.oneTapMobile.map((number, index) => (
                      <p key={index}>{number}</p>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
