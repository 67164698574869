import React from 'react';
import { Link } from 'react-router-dom';
import { eventsData } from '../data/eventsData';
import '../styles/HomePageEventCards.css';

// Import all images from the EventCards directory
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const EventImages = importAll(require.context('../assets/EventCards', false, /\.(png|jpe?g|svg)$/));

const HomePageEventCards = () => {
  const currentDate = new Date();
  console.log('Current date:', currentDate.toLocaleString());
  
  const upcomingEvents = eventsData
    .map(event => {
      // Use the last day of the date range for sorting
      const lastDay = event.eventDateRange?.days[event.eventDateRange.days.length - 1] || 1;
      const month = event.eventDateRange?.month || event.month;
      const year = event.year || currentDate.getFullYear();
      
      // Create date object for the event's end time if specified
      let eventEndDate;
      if (event.endTime) {
        const [time, period] = event.endTime.split(' ');
        const [endHour, endMinute] = time.split(':');
        const endTimeString = `${month} ${lastDay} ${year} ${event.endTime}`;
        eventEndDate = new Date(endTimeString);
        // Convert 12-hour format to 24-hour format
        let hours = parseInt(endHour);
        if (period && period.toUpperCase() === 'PM' && hours < 12) hours += 12;
        if (period && period.toUpperCase() === 'AM' && hours === 12) hours = 0;
        eventEndDate.setHours(hours, parseInt(endMinute), 0, 0);
      }
      
      // Create date object for event start time
      const timeString = event.time.replace('EST', '').trim(); // Remove EST if present
      const dateString = `${month} ${lastDay} ${year} ${timeString}`;
      const startDate = new Date(dateString);
      
      console.log(`Processing Event: ${event.title}`);
      console.log(`- Date String: ${dateString}`);
      console.log(`- Start Date: ${startDate.toLocaleString()}`);
      console.log(`- End Date: ${eventEndDate ? eventEndDate.toLocaleString() : 'N/A'}`);
      
      return {
        ...event,
        dateObject: startDate,
        endDateObject: eventEndDate
      };
    })
    .filter(event => {
      // If event has an end time, show until that time, otherwise show until start time
      const compareDate = event.endDateObject || event.dateObject;
      const shouldShow = compareDate > currentDate;
      console.log(`\nFiltering ${event.title}:`);
      console.log(`- Compare date: ${compareDate.toLocaleString()}`);
      console.log(`- Current date: ${currentDate.toLocaleString()}`);
      console.log(`- Should show: ${shouldShow}`);
      return shouldShow;
    })
    .sort((a, b) => a.dateObject.getTime() - b.dateObject.getTime())
    .slice(0, 4);

  console.log('\nFinal upcoming events:', upcomingEvents.map(e => e.title));
  console.log('Available images:', EventImages);

  return (
    <div className="home-page-event-cards">
      {upcomingEvents.length === 0 ? (
        <div>No upcoming events</div>
      ) : (
        upcomingEvents.map((event, index) => (
          <Link 
            to={`/events/${event.id}`} 
            key={index} 
            className="home-page-event-card"
            data-event={event.title}
          >
            <div className="event-title">{event.title}</div>
            {event.image && (
              <img 
                src={event.title === "Black History Celebration" ? require('../assets/EventCards/black history flyer.jpg') : EventImages[event.image]}
                alt={event.title} 
                className="event-image"
              />
            )}
          </Link>
        ))
      )}
    </div>
  );
};

export default HomePageEventCards;
