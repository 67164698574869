    // Events array is empty until new 2025 events are added
    // Example format for future events:
    // { 
    //   id: 1, 
    //   title: "Event Name", 
    //   category: "Category",
    //   month: "Month",
    //   date: "Month Day", // Display format
    //   year: 2025, // Used for filtering only
    //   time: "00:00 AM/PM", 
    //   inPerson: true/false, 
    //   online: true/false, 
    //   image: "image-name.svg" 
    // }

export const eventsData = [
    { 
        id: 1, 
        title: "Prayer Week",
        category: "Prayer Meeting",
        month: "January",
        date: "January 6th - 10th",
        eventDateRange: {
            month: "January",
            days: [6, 7, 8, 9, 10]
        },
        year: 2025,
        time: "07:00 PM",
        inPerson: false,
        online: true,
        image: "prayerweek.png",
        description: "Join us for a week of prayer and spiritual renewal. Each evening we will gather online for powerful prayer sessions.",
        meetingDetails: {
            id: "898-0416-6719",
            password: "4620327562",
            oneTapMobile: [
                "+13052241968,,89804166719#,,,,*4620327562# US",
                "+13092053325,,89804166719#,,,,*4620327562# US",
                "+16469313860,,89804166719#,,,,*4620327562# US"
            ]
        },
        location: "Online via Zoom"
    },
    {
        id: 2,
        title: "Valentines Dinner",
        category: "Other",
        month: "February",
        date: "February 15th",
        eventDateRange: {
            month: "February",
            days: [15]
        },
        year: 2025,
        time: "07:00 PM",
        inPerson: true,
        online: false,
        image: "vdinner.png",
        description: "Enjoy a delightful evening with us at our Valentine's dinner celebration!\n\nPricing:\nAdults - $50\nAges 5 to 12 - $35\n\nJoin us for a memorable night filled with love and laughter.",
        location: "Joseph Anthony Manning Fellowship Hall, 3080 White Plains Road, Bronx, NY 10467"
    },
    {
        id: 3,
        title: "Bible Study",
        category: "Bible Study",
        month: "January",
        date: "January 24th",
        eventDateRange: {
            month: "January",
            days: [24]
        },
        year: 2025,
        time: "07:00 PM",
        inPerson: false,
        online: true,
        image: "bstudy.png",
        description: "Join us for our weekly Bible Study as we dive deep into God's Word.",
        location: "Online via Zoom",
        meetingDetails: {
            id: "898-0416-6719",
            password: "4620327562",
            oneTapMobile: [
                "+13052241968,,89804166719#,,,,*4620327562# US",
                "+13092053325,,89804166719#,,,,*4620327562# US",
                "+16469313860,,89804166719#,,,,*4620327562# US"
            ]
        }
    },
    {
        id: 4,
        title: "First Fruits Rally",
        category: "Fundraiser",
        month: "May",
        date: "May 18th",
        eventDateRange: {
            month: "May",
            days: [18]
        },
        year: 2025,
        time: "11:00 AM",
        inPerson: true,
        online: true,
        image: "rally2025.png",
        description: "Sowing Boldly, Reaping Abundantly, and Building God's Kingdom\n\nJoin us in-person or online for our 2025 first fruits rally fundraiser!",
        location: "3080 White Plains Road, Bronx, NY 10467",
    },
    {
        id: 5,
        title: "Vision Board Event",
        category: "Youth Meeting",
        month: "February",
        date: "February 1st",
        eventDateRange: {
            month: "February",
            days: [1]
        },
        year: 2025,
        time: "06:00 PM",
        inPerson: true,
        online: false,
        image: "vision.png",
        description: "\"Where there is no vision the people get out of control but whoever obeys instruction is happy (Proverbs 29:18)\"\n\nInvite your friends and family to the Youth Department's vision board event! **Kindly consider donating $5** to help realize our vision, as supplies will be provided for the event.",
        location: "Joseph Anthony Manning Fellowship Hall, 3080 White Plains Road, Bronx, NY 10467"
    },
    {
        id: 6,
        title: "Women's Wednesday",
        category: "Zoom Meeting",
        month: "February",
        date: "February 5th",
        eventDateRange: {
            month: "February",
            days: [5]
        },
        year: 2025,
        time: "07:00 PM",
        inPerson: false,
        online: true,
        image: "ww5th.png",
        description: "**Zoom Meeting ID: 881 3411 2878**\n**Password: WWMCPC**\n\nOur bi-weekly Women Wednesday Zoom calls resume this Wednesday (2/5/24) just in time for Black History month! Come catch up with us, we look forward to seeing you!",
        location: "Online via Zoom"
    },
    {
        id: 7,
        title: "Prayer Meeting",
        category: "Prayer Meeting",
        month: "February",
        date: "February 14th",
        eventDateRange: {
            month: "February",
            days: [14]
        },
        year: 2025,
        time: "07:00 PM",
        endTime: "09:00 PM",
        inPerson: false,
        online: true,
        image: "prayer7th.png",
        description: "Join us for our bi-weekly Friday prayer meeting as we call upon the name of the Lord in prayer.",
        location: "Online Via Zoom",
        meetingDetails: {
            id: "898-04166-719",
            password: "4620327562",
            oneTapMobile: [
                "+13052241968,,89804166719#,,,,*4620327562# US",
                "+13092053325,,89804166719#,,,,*4620327562# US",
                "+16469313860,,89804166719#,,,,*4620327562# US"
            ]
        }
    },
    {
        id: 8,
        title: "Bible Study",
        category: "Bible Study",
        month: "February",
        date: "February 21st",
        eventDateRange: {
            month: "February",
            days: [21]
        },
        year: 2025,
        time: "07:00 PM EST",
        endTime: "09:00 PM",
        inPerson: false,
        online: true,
        image: "bstudy14th.png",
        description: "Join us for our weekly Bible Study as we dive deep into God's Word.",
        location: "Online via Zoom"
    },
    {
        id: 9,
        title: "Black History Celebration",
        category: "Other",
        month: "February",
        date: "February 23rd",
        eventDateRange: {
            month: "February",
            days: [23]
        },
        year: 2025,
        time: "05:00 PM",
        inPerson: true,
        online: true,
        image: "blackhistory23rd.png",
        description: "Join us as we honor our Pioneers, Mother Stella Clarke, Sis. Gloria Wilson, and Missionary Ruby Burnham, during Mount Carmel Pentecostal Church's Black History Month celebration at 5PM on the last Sunday in February.",
        location: "3080 White Plains Road, Bronx New York, 10467"
    }
];
